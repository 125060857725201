import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down(701)]: {
                justifyContent: 'flex-start',
            },
        },
        dots: {
            [theme.breakpoints.down(400)]: {
                marginLeft: '10px !important',
                fontSize: '20px !important',
            },
        },
    });
